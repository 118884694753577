/* src/WatchPage.css */
.video-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  #fullscreen-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the whole screen while maintaining aspect ratio */
  }