.signin-container {
    display: flex;
    flex-direction: column;
    width: 85%; 
    margin: 2% auto;
  }
  
.signin-container .signin-button {
  width: 100%;
  box-sizing: border-box; 
}

  
  h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 30pt;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #212121;


  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  }

  a {
    color: #FF2A23 !important;
  }
  
  .input-group {
    width: 100%; /* Takes the full width of .signin-container */
    margin-bottom: 8px;
  }
  
  .input-group input {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    margin: 2px 0;
    background-color: #F0F0F0;
    border: none;
    border-radius: 12px;
    font-family: var(--font-stack);
    font-size: 11pt;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 22px 0 22px;
  }
  
  input[type="checkbox"] {
    -webkit-appearance: none; /* For Safari and older Chrome browsers */
    -moz-appearance: none;    /* For Firefox */
    appearance: none;         /* Standard syntax */
    
    /* Custom styles */
    background: white;
    border: 2px solid #FF2A23;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    width: 24px;
    height: 24px;
  }
  
  input[type="checkbox"]:checked {
    background-image: url('/public/checkmark.png');
    background-size: cover; /* Resize image to cover the whole checkbox */
    background-position: center; /* Center the image in the checkbox */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-color: #FF2A23; /* Background color for the checkbox */
  }
  
  .signin-button {
    width: 100%; /* Takes the full width of .signin-container */
    padding: 12px;
    background-color: var(--replay-red, #FF2A23); /* Use your brand color */
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font-stack);
    font-weight: 600;
  }
  
  .signin-button:hover {
    background-color: #dd0000; /* Darker shade for hover effect */
  }
  
  .alternative-signin .icons {
    display: flex;        /* Use flexbox to align items */
    justify-content: center; /* Center items horizontally */
    align-items: center;  /* Center items vertically */
    margin: 0 auto;       /* Center the container itself */
  }

  .icon {
    margin: 10px 5px; /* Add some space between the icons */
    width: 80px;
  }
  
  .signin-redirect {
    margin-top: 20px;
  }
  
  .signin-redirect a {
    color: var(--replay-red);
    text-decoration: none;
  }

  .alternative-signin {
    text-align: center;
    margin: 20px 0 40px;
  }
  
  .signup-link {
    text-align: center;
  }

  .remember-me {
    margin-left: 5px;
  }

  .back-button {
    border: none;
    background: none;
    cursor: pointer;
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0px !important;
  }
  
  .back-button img {
    width: 30px; /* Adjust size as needed */
    height: auto;
  }