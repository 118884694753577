.mobile-container {
    margin: 0 auto; /* Center the container */
    max-width: 375px; /* Typical width of a mobile device */
    min-height: 740px;
    max-height: 100vh; /* Full height of the viewport */
    overflow-x: hidden; /* Prevent horizontal scroll */
    position: relative; /* For positioning child elements */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: adds a shadow for better visualization */
    border: 1px solid #ddd; /* Optional: adds a border around the container */
    padding: 10px;
  }
  